import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http'
import { ManageLoggedUser } from './manage-logged-user.service'
import { HttpClientOptionsService } from './http-client-options.service'
import { environment } from '../../../../environments/environment'
import { catchError, throwError } from 'rxjs'
import { HubDefaultHttpResponse, RequestLoginTokenReturn } from '../types/AuthenticationTypes'

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    constructor(
        private httpClient: HttpClient,
        private httpClientOptions: HttpClientOptionsService,
        private manageLoggedUser: ManageLoggedUser,
    ) {}

    authorizeAccountUser(accountUserEmail: any, accountUserPassword: any, captchaResponse: any, code2fa: any, sessionId: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Theme': 'partnershub',
        })

        if (code2fa) headers = headers.append('Authorization', sessionId)

        let body = `client_id=${environment.AUTH_CLIENT_WEBAPP}&password=${accountUserPassword}&username=${accountUserEmail}&grant_type=${'password'}`

        if (captchaResponse) body += `&captcha_token=${captchaResponse}`
        if (code2fa) body += `&code=${code2fa}`

        return this.httpClient.post<RequestLoginTokenReturn>(`${environment.AUTH_URL_BASE}/connect/token`, body, { headers: headers })
    }

    sendResetAccountUserPassword(accountUserEmail: any) {
        const headers = new HttpHeaders({ 'X-Theme': 'partnershub' })

        return this.httpClient.post<HttpResponse<any>>(
            `${environment.ID_URL_BASE}/operations/reset-password`,
            { email: `${accountUserEmail}` },
            { headers: headers, observe: 'response' },
        )
    }

    resetAccountUserPassword(passwordAndTokenToUpdatePassword: any) {
        const headers = new HttpHeaders({ 'X-Theme': 'partnershub' })

        return this.httpClient.patch<HubDefaultHttpResponse>(
            `${environment.ID_URL_BASE}/operations/set-password`,
            passwordAndTokenToUpdatePassword,
            { headers: headers },
        )
    }

    getDefinePasswordValidationTokenContent(definePasswordValidationToken: any) {
        const headers = new HttpHeaders({ 'X-Theme': 'partnershub' })

        return this.httpClient.get<HubDefaultHttpResponse>(
            `${environment.ID_URL_BASE}/operations/validate/${definePasswordValidationToken}`,
            { headers: headers },
        )
    }

    createAccountUserAfterPreRegister(accountUserToCreateAfterPreRegister: any) {
        return this.httpClient.post<HubDefaultHttpResponse>(
            `${environment.URL_BASE}/partnerhub/open/account-users/create-account-user-after-pre-register`,
            accountUserToCreateAfterPreRegister,
        )
    }

    verifyIfAccountUserEmailExists(accountUserEmail: any) {
        return this.httpClient.get<HubDefaultHttpResponse>(
            `${environment.URL_BASE}/partnerhub/open/account-user-authentication/verify-account-user-email/${accountUserEmail}`,
        )
    }

    singInAccountUser(accountUserToken: any) {
        return this.httpClient.get<HubDefaultHttpResponse>(
            `${environment.URL_BASE}/partnerhub/open/account-user-authentication/singin/${accountUserToken}`,
        )
    }

    logOutAccountUser(accountUserToken: any) {
        return this.httpClient.delete<HubDefaultHttpResponse>(
            `${environment.URL_BASE}/partnerhub/open/account-user-authentication/logout/${accountUserToken}`,
        )
    }

    getAccountUserPermissions() {
        return this.httpClient
            .get<any>(
                `${environment.URL_BASE}/partnerhub/account-user-authentication/get-account-user-roles`,
                this.httpClientOptions.getPartnerhubAuthorization(),
            )
            .pipe(
                catchError(error => {
                    if (error.status == 401) {
                        this.manageLoggedUser.removeLoggedAccountUser()
                        window.location.href = '/'
                    }
                    return throwError(error)
                }),
            )
    }

    generate2fConfigurationQrCodeUsingAccessToken(auth: string) {
        return this.httpClient.post<HubDefaultHttpResponse>(
            `${environment.ID_URL_DOMAIN}/profile/v2/me/security/mfa`,
            { type: 1 },
            {
                headers: this.httpClientOptions.customHeadersAuthorization('bearer', auth),
            },
        )
    }

    generate2fConfigurationQrCodeUsingSessionId(auth: string, email: string) {
        return this.httpClient.post<HubDefaultHttpResponse>(
            `${environment.ID_URL_DOMAIN}/profile/v2/mfa/${email}`,
            { type: 1 },
            {
                headers: this.httpClientOptions.customHeadersAuthorization('simple', auth),
            },
        )
    }

    activate2faUsingAccessToken(authenticatorCode: string, auth: string) {
        return this.httpClient.post<HubDefaultHttpResponse>(
            `${environment.ID_URL_DOMAIN}/profile/v2/me/security/mfa/active`,
            { code: authenticatorCode },
            {
                headers: this.httpClientOptions.customHeadersAuthorization('bearer', auth),
            },
        )
    }

    activate2faUsingUsingSessionId(authenticatorCode: string, email: string, auth: string) {
        return this.httpClient.post<HubDefaultHttpResponse>(
            `${environment.ID_URL_DOMAIN}/profile/v2/mfa/${email}/active`,
            { code: authenticatorCode },
            {
                headers: this.httpClientOptions.customHeadersAuthorization('simple', auth),
            },
        )
    }

    reset2fa(recoveryCode: string, email: string, auth: string) {
        return this.httpClient.post<HubDefaultHttpResponse>(
            `${environment.ID_URL_DOMAIN}/profile/v2/mfa/${email}/reset`,
            { recovery_code: recoveryCode },
            {
                headers: this.httpClientOptions.customHeadersAuthorization('simple', auth),
            },
        )
    }

    remove2faUsingAccessToken(authenticatorCode: string, auth: string) {
        return this.httpClient.delete<HubDefaultHttpResponse>(
            `${environment.ID_URL_DOMAIN}/profile/v2/me/security/mfa`,
            this.httpClientOptions.setBodyAndAuthorizationForDeleteRequest({ code: authenticatorCode }, auth, 'bearer'),
        )
    }

    send2faSmsUsingAccessToken(auth: string) {
        return this.httpClient.post<HubDefaultHttpResponse>(
            `${environment.ID_URL_DOMAIN}/profile/v2/me/security/mfa/send`,
            {},
            {
                headers: this.httpClientOptions.customHeadersAuthorization('bearer', auth),
            },
        )
    }

    get2faConfigurationUsingAccessToken(auth: string) {
        return this.httpClient.get<HubDefaultHttpResponse>(`${environment.ID_URL_DOMAIN}/profile/v2/me/security/mfa`, {
            headers: this.httpClientOptions.customHeadersAuthorization('bearer', auth),
        })
    }
}
